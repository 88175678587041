import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {POST_WEBCALLBACK, WEBCALLBACK} from "../../../actions/app/webcallback/webcallback.actions";

import {reset} from 'redux-form'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const webcallbackMiddleware = () => next => action => {
    next(action)

    switch (action.type){
        case POST_WEBCALLBACK:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: WEBCALLBACK}),
                setLoader({state: true, entity: WEBCALLBACK})
            ])
            break

        case `${WEBCALLBACK} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: WEBCALLBACK,
                    body: 'Votre demande a bien été envoyée',
                    type: 'success',
                }),
                reset('webcallback'),
                setError({state: false, entity: WEBCALLBACK}),
                setLoader({state: false, entity: WEBCALLBACK})
            ])
            break

        case `${WEBCALLBACK} ${API_ERROR}`:
            next([
                setNotification({
                    entity: WEBCALLBACK,
                    body: action.payload.data.response.data?.message ?? '',
                    type: 'error',
                    title: 'Attention !',
                    timeout: "0"
                }),
                setError({state: true, entity: WEBCALLBACK}),
                setLoader({state: false, entity: WEBCALLBACK})
            ])
            break

        default:
            break
    }
    return null
}
