import {POST_UPDATE_BIRTH_INFOS_TIERS, TIERS, GET_TIERS, setTiers} from '../../../actions/app/tiers/tiers.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {logAction} from "../../../actions/core/logs/logs.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {CREATE_ACCOUNT} from "../../../actions/app/contracts/account.contract.actions";

export const tiersMiddleware = () => next => action => {
    next(action)

    let message = null
    switch (action.type) {

        case GET_TIERS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: TIERS}),
                setLoader({state: true, entity: TIERS}),
                logAction({type: 'SHOW_TIERS', entity:TIERS, tiers: action.payload.otherData.id})
            ])
            break

        case `${TIERS} ${API_SUCCESS}`:
            next([
                setTiers({data: action.payload.data}),
                setError({state: false, entity: TIERS}),
                setLoader({state: false, entity: TIERS})
            ])
            break

        case `${TIERS} ${API_ERROR}`:
            next([
                setError({state: true, entity: TIERS}),
                setLoader({state: false, entity: TIERS})
            ])
            break

        case POST_UPDATE_BIRTH_INFOS_TIERS:
            next([
                apiRequest({body: action.payload.body, method: 'POST', url: action.payload.data, entity: POST_UPDATE_BIRTH_INFOS_TIERS, otherData: action.payload.otherData}),
                setLoader({state: true, entity: POST_UPDATE_BIRTH_INFOS_TIERS})
            ])
            break

        case `${POST_UPDATE_BIRTH_INFOS_TIERS} ${API_SUCCESS}`:
            next([
                setNotification({entity: POST_UPDATE_BIRTH_INFOS_TIERS, body: message, type: 'success', title: 'Modification du client enregistrée!'}),
                setLoader({state: false, entity: POST_UPDATE_BIRTH_INFOS_TIERS}),
                setError({state: false, entity: POST_UPDATE_BIRTH_INFOS_TIERS}),
            ])
            break

        case `${POST_UPDATE_BIRTH_INFOS_TIERS} ${API_ERROR}`:
            message= `<p>Votre demande n'a pas pu être envoyée. ${action.payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({entity: CREATE_ACCOUNT, body: message, type: 'error', title: 'Erreur lors de la modification !', timeout: '0'}),
                setError({state: true, entity: CREATE_ACCOUNT}),
                setLoader({state: false, entity: CREATE_ACCOUNT}),
            ])
            break
        default:
            break
    }
    return null
}
