import _ from 'lodash';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, formValueSelector, reduxForm } from 'redux-form';
import { postModifieRib } from '../../../../../../../../redux/actions/app/contracts/avenants.contract.actions';
import { fetchContract } from '../../../../../../../../redux/actions/app/contracts/contracts.actions';
import {
    getBanqueContract,
    getContract,
    getIbanBanqueContract,
    getIdSouscripteurContract,
    getNomSouscripteurContract
} from '../../../../../../../../redux/selectors/contracts/contracts.selectors';
import { getAmendmentChangeIbanContractLink, getContractLink } from '../../../../../../../../redux/selectors/links/links.selectors';
import { getLoadingEntity } from '../../../../../../../../redux/selectors/ui/ui.selectors';
import loader from '../../../../../../../Commun/Spinners/Loader/Loader';
import ModifieRib from './ModifieRib';
import validate from './validate';

const mapStateToProps = (state, ownProps) => {
    const { id } = ownProps.match.params;
    const formName = `modifie-rib-${id}`;
    const selector = formValueSelector(formName);

    return {
        query: _.replace(getContractLink(state), '{id}', id),
        tiers: getIdSouscripteurContract(state),
        iban: getIbanBanqueContract(state),
        nomSouscripteur: getNomSouscripteurContract(state),
        contract: getContract(state),
        queryPost: getAmendmentChangeIbanContractLink(state),
        form: formName,
        loading: getLoadingEntity(state, formName),
        ibanIsCheck: Boolean(selector(state, 'domiciliation') && selector(state, 'bic')),
        titulaire: selector(state, 'titulaire'),
        banque: getBanqueContract(state),
        initialValues: {
            contract: id,
        },
        type: selector(state, 'type'),
        relationship: selector(state, 'relationship'),
    };
};

const mapDispatchToProps = {
    fetchContract,
    changeValue: change,
    postModifieRib,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { query, contract, form, queryPost, tiers } = stateProps;
    const { fetchContract, postModifieRib, changeValue } = dispatchProps;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        load: () => (_.isEmpty(contract) ? fetchContract({ query }) : null),
        changeValue: (field, value) => changeValue(form, field, value),
        onSubmit: (formulaire) => {
            postModifieRib({
                form: {
                    ...formulaire,
                    tiers,
                },
                query: queryPost,
                formName: form,
            });
        },
    };
};

const ModifieRibContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        validate,
    }),
    loader(),
)(ModifieRib);

export default ModifieRibContainer;
