import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_CHANGE_FRACTIONNEMENT} from "../../../actions/app/contracts/fractionnement.contract.actions";
import _ from "lodash";

export const changeFractionnementMiddleware = () => next => action => {
    next(action)
    let message;

    switch (action.type){

        case POST_CHANGE_FRACTIONNEMENT:
            let data = new FormData()
            let clone = _.cloneDeep(action.payload.body)
            if (clone.iban){
                clone.iban = clone.iban.replace(/[-]/g, '')
            } else {
                delete clone.iban
            }

            Object.keys(clone).forEach(key => {
                if (key === 'files') {
                    data.append('files[G]', clone[key])
                } else {
                    data.append(key, clone[key])
                }
            })

            next([
                apiRequest({body: data, method: 'POST', url: action.payload.data, entity: POST_CHANGE_FRACTIONNEMENT, otherData: action.payload.meta}),
                setLoader({state: true, entity: action.payload.meta.formName})
            ])
            break

        case `${POST_CHANGE_FRACTIONNEMENT} ${API_SUCCESS}`:
            const { formName, createChrono } = action.payload.meta.otherData

            message = "Votre demande a bien été prise en compte ! <br/> Pour finaliser la demande, invitez votre client à signer électroniquement le mandat SEPA qui lui a été envoyé par email."

            if(createChrono){
                message = "Votre demande a bien été prise en compte. <br/> Un email d'information vient de vous être envoyé."
            }

            next([
                setNotification({entity: POST_CHANGE_FRACTIONNEMENT, type: 'success', body: message, title: 'Merci !'}),
                setError({state: false, entity: formName}),
                setLoader({state: false, entity: formName})
            ])

            break

        case `${POST_CHANGE_FRACTIONNEMENT} ${API_ERROR}`:
            message = `<p>Une erreur est survenue.</p><p>${action.payload.data.response.data?.message ?? ''}</p>`
            next([
                setNotification({entity: POST_CHANGE_FRACTIONNEMENT, type: 'error', body: message, title: 'Attention !', timeout: '0'}),
                setError({state: true, entity: action.payload.meta.otherData.formName}),
                setLoader({state: false, entity: action.payload.meta.otherData.formName})
            ])
            break

        default:
            break
    }
    return null
}
