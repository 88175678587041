import {createSelector} from 'reselect'
import _ from 'lodash'

const contractsSelector = state => state.contracts

/***** DATA CONTRACTS *****/
export const getDataContracts = createSelector(
    contractsSelector,
    contracts => contracts.data
)

/***** META CONTRACTS *****/
export const getMetaContracts = createSelector(
    contractsSelector,
    contracts => contracts.meta || {}
)

/***** LINKS CONTRACTS *****/
export const getLinksContracts = createSelector(
    contractsSelector,
    contracts => contracts.links
)


/***** CONTRACT *****/
export const getContract = createSelector(
    contractsSelector,
    contract => contract
)

/***** DOCUMENTS CONTRACT *****/
export const getDocumentsContract = createSelector(
    contractsSelector,
    contract => contract.documents?.filter(document => document.code && document.code.trim() !== '') || []
)

export const getWaitingDocumentReglementContract = createSelector(
    getDocumentsContract,
    documents => _.find(documents, d => {
        return d.code === 'K' && !d.recu
    })

)
export const getPrelevementContract = createSelector(
    contractsSelector,
    contract => contract.prelevement
)
export const getHivernagesContract = createSelector(
    contractsSelector,
    contract => contract.hivernages || []
)
export const getDernierHivernageContract = createSelector(
    getHivernagesContract,
    hivernages => hivernages[0] || null
)
export const getIsEligibleHivernageContract = createSelector(
    contractsSelector,
    contract => contract.is_eligible_hivernage || false
)
export const getCanActivateHivernageContract = createSelector(
    contractsSelector,
    contract => contract.can_activate_hivernage || false
)
/***** GARANTIES CONTRAT *****/
export const getGarantiesContract = createSelector(
    contractsSelector,
    contract => contract.garanties
)

export const getContractHamon = createSelector(
    contractsSelector,
    data => data.hamon
)

export const getContractGaranties = createSelector(
    contractsSelector,
    data => _.filter(data.garanties, ['type', 'G'])
)

export const getContractGarantiesDA = createSelector(
    getContractGaranties,
    data => _.filter(data, ['code', 'DA'])
)
/***** ACCIDENTS CONTRACT *****/
export const getAccidentsContract = createSelector(
    contractsSelector,
    contract => contract.accidents || []
)
/***** QUITTANCES CONTRACT *****/
export const getQuittancesContract = createSelector(
    contractsSelector,
    contract => contract.quittances || []
)

export const getQuittanceWithmontantComptantOrFirstQuittanceContract = createSelector(
    getQuittancesContract,
    quittance => _.find(quittance, q => { return q.montantComptant > 0 }) || quittance[0] || []
)
/***** ACTIVITIES CONTRACT *****/
export const getActivitiesContract = createSelector(
    contractsSelector,
    contract => contract.activities
)

/***** VEHICULE CONTRACT *****/
export const getVehiculeContract = createSelector(
    contractsSelector,
    contract => contract.vehicule || {}
)

export const getIsNVEIContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_NVEI'
)

export const getIsVAEContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_VAE'
)

export const getIsACLContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_ACL' || vehicule.codeProduit === '229127_AC' || vehicule.codeProduit === '229127_CC' || vehicule.codeProduit === '229127_PR' || vehicule.codeProduit === '229127_FR'
)

export const cantReportAccidentContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '229127_AC' || vehicule.codeProduit === '229127_CC'
)

export const getIsAIMCOContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_QMCO' || vehicule.codeProduit === '7311_2MCO'
)

export const getCodeProduitVehicule = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit
)

export const getAccessoireContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.valeurAccessoires
)

export const getCylindreeContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.cylindree
)

export const getImmatriculationContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.immatriculation
)

export const getMarqueVehiculeContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.marque
)

export const getModeleVehiculeContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.modele
)

/***** SOUSCRIPTEUR CONTRACT *****/
export const getSouscripteurContract = createSelector(
    contractsSelector,
    contract => contract.souscripteur || {}
)
export const getNomSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.nom
)
export const getIdSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.id
)
export const getPhoneSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.phone
)
export const getEmailSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.email
)
export const getAdresseSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.adresse
)
export const getVilleNaissanceSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.ville_naissance
)
export const getPaysNaissanceSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.pays_naissance
)
export const getCodePaysNaissanceSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.code_pays_naissance
)
export const getNomNaissanceSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.nom_naissance
)
export const getBeneficiairesEffectifsContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.beneficiaires_effectifs || []
)
export const getStatutJuridiqueSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.statut_juridique
)
export const getSirenSiretSouscripteurContract = createSelector(
    getSouscripteurContract,
    souscripteur => souscripteur.num_siren_siret
)

/***** SITUATION CONTRACT *****/
export const getSituationContract = createSelector(
    contractsSelector,
    contract => contract.situation
)
export const getDateFinContract = createSelector(
    getSituationContract,
    situation => situation.dateFin
)
export const getDateAffaireNouvelleContract = createSelector(
    getSituationContract,
    situation => situation.dateAffaireNouvelle
)

export const getStatutCodeContract = createSelector(
    getSituationContract,
    situation => situation?.statutCode
)

export const getDateEcheanceContract = createSelector(
    getSituationContract,
    situation => situation?.dateEcheance
)

/***** BANQUE *****/
export const getBanqueContract = createSelector(
    contractsSelector,
    contract => contract.banque || {}
)
export const getIbanBanqueContract = createSelector(
    getBanqueContract,
    banque => banque.iban
)

/***** FLEET *****/
export const getFleetContract = createSelector(
    contractsSelector,
    contract => contract.fleet
)

/***** FLAT DATA CONTRACT *****/
export const getIdContract = createSelector(
    contractsSelector,
    contract => contract.id
)

export const getAdhesionPrincipaleContract = createSelector(
    contractsSelector,
    contract => contract.adhesionPrincipale
)

export const getAccidentClaimLinkContract = createSelector(
    contractsSelector,
    contract => contract.accident_claim_link
)

export const getAccidentMobClaimLinkContract = createSelector(
    contractsSelector,
    contract => contract.accident_mob_claim_link
)

export const getUploadAppLinkContract = createSelector(
    contractsSelector,
    contract => contract.upload_app_link
)

/* Prelevement */
export const getContractPrelevement = createSelector(
    contractsSelector,
    contract => contract.prelevement
)

export const getIsFleetContract = createSelector(
    contractsSelector,
    contract => contract.flotte
)

export const getIsWakamContract = createSelector(
    contractsSelector,
    contract => contract?.compagnie === "WAKAM"
)

export const getNumeroPoliceContract = createSelector(
    contractsSelector,
    contract => contract.numeroPolice
)

/********* Tiers Payeur *********/
export const getTiersPayeurContract = createSelector(
    contractsSelector,
    contract => contract.tiers_payeur || {}
)
