import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {POST_FEEDBACK, FEEDBACK} from "../../../actions/app/feedback/feedback.actions";

import {reset} from 'redux-form'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const feedbackMiddleware = () => next => action => {
    next(action)

    switch (action.type){
        case POST_FEEDBACK:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: FEEDBACK}),
                setLoader({state: true, entity: FEEDBACK})
            ])
            break

        case `${FEEDBACK} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: FEEDBACK,
                    body: 'Votre message a bien été envoyé et nous vous ferons un retour sous quelques jours.',
                    type: 'success', title: 'Merci de contribuer à l\'amélioration de votre espace !'
                }),
                reset('feedback'),
                setError({state: false, entity: FEEDBACK}),
                setLoader({state: false, entity: FEEDBACK})
            ])
            break

        case `${FEEDBACK} ${API_ERROR}`:
            next([
                setNotification({
                    entity: FEEDBACK,
                    body: action.payload.data.response.data?.message ?? '',
                    type: 'error',
                    title: 'Attention !',
                    timeout: "0"
                }),
                setError({state: true, entity: FEEDBACK}),
                setLoader({state: false, entity: FEEDBACK})
            ])
            break

        default:
            break
    }
    return null
}
