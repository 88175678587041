import _ from "lodash";
import moment from "moment";

import {API_ERROR, API_SUCCESS, apiRequest} from '@/redux/actions/core/api/api.actions'
import {
    setError,
    setErrorResponse,
    setFilters,
    setLoader
} from '@/redux/actions/app/ui/ui.actions'
import {
    FETCH_FLEET_CONTRACT,
    FLEET_CONTRACT, POST_ADD_FLEET_CONTRACT, POST_DELETE_VEHICLES, POST_OUT_FLEET_CONTRACT,
    setFleetContract
} from "@/redux/actions/app/contracts/fleet.contract.actions";
import {setNotification} from "@/redux/actions/core/notifications/notifications.actions";

export const fleetContractsMiddleware = () => next => action => {
    next(action)
    let clone = null
    switch (action.type){

        case FETCH_FLEET_CONTRACT:
            let url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters){
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    if (value !== null){
                        url = `${url}&${key}=${value}`
                    }
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: FLEET_CONTRACT}),
                setFilters({entity: FLEET_CONTRACT, filters: {...action.payload.filters}}),
                setLoader({state: true, entity: FLEET_CONTRACT})
            ])
            break

        case `${FLEET_CONTRACT} ${API_SUCCESS}`:
            next([
                setFleetContract({data: action.payload.data}),
                setError({state: false, entity: FLEET_CONTRACT}),
                setLoader({state: false, entity: FLEET_CONTRACT})
            ])
            break

        case `${FLEET_CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: FLEET_CONTRACT}),
                setErrorResponse({entity: FLEET_CONTRACT, data: action?.payload?.data?.response?.data}),
                setLoader({state: false, entity: FLEET_CONTRACT})
            ])
            break

        case POST_OUT_FLEET_CONTRACT:
            clone = _.cloneDeep(action.payload.body);
            clone.date = moment(clone.date).format('L');

            next([
                apiRequest({body: (clone), method: 'POST', url: action.payload.data, entity: POST_OUT_FLEET_CONTRACT, otherData: action.payload.meta.otherData}),
                setLoader({state: true, entity: action.payload.meta.otherData})
            ])
            break;

        case `${POST_OUT_FLEET_CONTRACT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: action.payload.meta.otherData}),
                setNotification({entity: action.payload.meta.otherData, body: null, title: 'Votre demande a bien été enregistrée', type: 'success'}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        case `${POST_OUT_FLEET_CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: action.payload.meta.otherData}),
                setNotification({entity: action.payload.meta.otherData, body: `${action.payload.data.response.data.message} ${action.payload.data.response.data?.message ?? ''}`, title: 'Une erreur est survenue !', type: 'error'}),
                setLoader({state: false, entity: action.payload.meta.otherData})
            ])
            break

        case POST_ADD_FLEET_CONTRACT:
            let cloneAdd = _.cloneDeep(action.payload.body);
            cloneAdd["date_mec"] = moment(cloneAdd["date_mec"]).format('L');
            cloneAdd["date_achat"] = moment(cloneAdd["date_achat"]).format('L');

            delete cloneAdd.type;
            delete cloneAdd.cylindree;

            let dataForm = new FormData()

            for (let [key, value] of Object.entries(cloneAdd)) {
                dataForm.append([key], value)
            }

            next([
                apiRequest({body: dataForm, method: 'POST', url: action.payload.data, entity: POST_ADD_FLEET_CONTRACT}),
                setLoader({state: true, entity: POST_ADD_FLEET_CONTRACT})
            ])
            break;

        case `${POST_ADD_FLEET_CONTRACT} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_ADD_FLEET_CONTRACT}),
                setNotification({entity: POST_ADD_FLEET_CONTRACT, body: null, title: 'Le véhicule a bien été enregistré', type: 'success'}),
                setLoader({state: false, entity: POST_ADD_FLEET_CONTRACT})
            ])
            break

        case `${POST_ADD_FLEET_CONTRACT} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_ADD_FLEET_CONTRACT}),
                setNotification({entity: POST_ADD_FLEET_CONTRACT, body: `${action.payload.data.response.data.message} ${action.payload.data.response.data?.message ?? ''}`, title: 'Une erreur est survenue !', type: 'error', timeout: '0'}),
                setLoader({state: false, entity: POST_ADD_FLEET_CONTRACT})
            ])
            break

        case POST_DELETE_VEHICLES:
            clone = _.cloneDeep(action.payload.body)

            delete clone.vehicules
            clone.date_sortie =  moment(clone.date_sortie).format('YYYY-MM-DD')

            next([
                apiRequest({
                    body: clone,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_DELETE_VEHICLES
                }),
                setLoader({state: true, entity: POST_DELETE_VEHICLES})
            ])
            break

        case `${POST_DELETE_VEHICLES} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_DELETE_VEHICLES,
                    body: `<p>La sortie des véhicules de la flotte a été effectuée</p>`,
                    type: 'success',
                    title: 'Sortie des véhicules effectuée !'
                }),
                setLoader({state: false, entity: POST_DELETE_VEHICLES}),
                setError({state: false, entity: POST_DELETE_VEHICLES}),
            ])
            break

        case `${POST_DELETE_VEHICLES} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DELETE_VEHICLES,
                    body: `<p>Votre demande n'a pas pu être envoyée. ${action.payload.data.response.data?.message ?? ''}</p>`,
                    type: 'error',
                    title: 'Erreur lors de la suppression !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_DELETE_VEHICLES}),
                setLoader({state: false, entity: POST_DELETE_VEHICLES}),
            ])
            break

        default:
            break
    }
    return null
}

