import {
    SIGNATURES,
    FETCH_SIGNATURES,
    setSignatures,
    POST_SEND_SIGNATURE
} from '../../../actions/app/signatures/signatures.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const signaturesMiddleware = () => next => action => {
    next(action)

    switch (action.type){

        case FETCH_SIGNATURES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: SIGNATURES}),
                setLoader({state: true, entity: SIGNATURES})
            ])
            break

        case `${SIGNATURES} ${API_SUCCESS}`:
            const { data } = action.payload.data

            next([
                setSignatures({data}),
                setError({state: false, entity: SIGNATURES}),
                setLoader({state: false, entity: SIGNATURES})
            ])
            break

        case `${SIGNATURES} ${API_ERROR}`:
            next([
                setError({state: true, entity: SIGNATURES}),
                setLoader({state: false, entity: SIGNATURES})
            ])
            break

        case POST_SEND_SIGNATURE:
            next([
                apiRequest({body: (action.payload.body), method: 'POST', url: action.payload.data, entity: POST_SEND_SIGNATURE}),
                setLoader({state: true, entity: POST_SEND_SIGNATURE})
            ])
            break

        case `${POST_SEND_SIGNATURE} ${API_SUCCESS}`:

            next([
                setNotification({entity: POST_SEND_SIGNATURE, body: "La procédure de signature a bien été envoyée", type: 'success', title: "Procédure envoyée!"}),
                setError({state: false, entity: POST_SEND_SIGNATURE}),
                setLoader({state: false, entity: POST_SEND_SIGNATURE})
            ])
            break

        case `${POST_SEND_SIGNATURE} ${API_ERROR}`:
            next([
                setNotification({entity: POST_SEND_SIGNATURE, body: `<p>Une erreur est survenue.</p><p>${action.payload.data.response.data?.message ?? ''}</p>`, type: 'error', title: "Erreur !"}),
                setError({state: true, entity: POST_SEND_SIGNATURE}),
                setLoader({state: false, entity: POST_SEND_SIGNATURE})
            ])
            break

        default:
            break
    }
    return null
}
