import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Card from '../../../../../Commun/Card/Card';
import { Link } from 'react-router-dom';
import { getReason } from '../../../../../../utils/translate';
import moment from 'moment';
import Cadre from '../../../../../Commun/Cadre/Cadre';
import { formatEuro, formatPhone } from '../../../../../../utils/function';
import DocumentsContractContainer from './DocumentsContract/DocumentsContractContainer';
import AccidentsContractContainer from './AccidentsContract/AccidentsContractContainer';
import QuittancesContractContainer from './QuittancesContract/QuittancesContractContainer';
import GarantiesContractContainer from './GarantiesContract/GarantiesContractContainer';
import ActivitiesContractContainer from './ActivitiesContract/ActivitiesContractContainer';
import FleetContainer from './Fleet/FleetContainer';
import VaeVehicule from './VaeVehicule/VaeVehicule';
import SignaturesContainer from './Signatures/SignaturesContainer';
import BeneficiairesEffectifContainer from './BeneficiairesEffectif/BeneficiairesEffectifContainer';
import { tiersPayeurRelation } from '../../../../../../utils/optionsSelect';
import _ from 'lodash';
import APILink from "../../../../../Commun/APILink/APILink";
import DocumentsClient from "@/components/Commun/DocumentsClient/DocumentsClient";
import Swal from "sweetalert2";

const ContractDetail = (props) => {
    const {
        isAIMCO,
        contract,
        isNVEI,
        isVAE,
        isACL,
        permissions,
        postPopup,
        postAccount,
        isCourtierPremium,
        fetchContract,
        query,
        loadingPopup,
        isFleet,
        history,
        hasWaitingReglement,
        quittance,
        postDeposit,
        paymentLoading,
        cantReportAccident,
        villeNaissance,
        paysNaissance,
        nomNaissance,
        tiersPayeur,
        submitDeleteTiersPayeur,
        canChangeFractionnement,
        isEligibleHivernage,
        dernier_hivernage,
        hamon,
        postConfirmResilHamon,
        query_resil_hamon,
        isWakam
    } = props;
    const {
        numeroPolice,
        prelevement,
        id,
        rum,
        apporteurNom,
        accident_mob_claim_link,
        accident_mob_claim_link_reason,
        accident_claim_link,
        accident_claim_link_reason,
        avenant_link,
        avenant_link_fid,
        avenant_link_reason,
        avenant_non_tarifant_link_reason,
        extract_vehicules_link,
        ri_link_reason,
        ri_link,
        tiers_has_account,
        libelleContrat,
        avantage
    } = contract;
    
    const {
        dateEcheance = null,
        statut = null,
        statutCode = null,
        echeance = null,
        dateAffaireNouvelle = null,
        fractionnement = null,
        motif = null
    } = contract.situation || {};
    const {
        marque = null,
        modele = null,
        cylindree = null,
        crm = null,
        dateCrm = null,
        dateMEC = null,
        immatriculation = null,
        genre = null,
        valeur = null,
        usage = null,
        lieuStationnement = null
    } = contract.vehicule || {};
    const {
        naissance = null,
        permis = null
    } = contract.conducteur || {};

    let diff2 = moment(moment().format('YYYY-MM-DD')).diff(contract.dernierCourrierCV, 'days');
    const [isClosed, setIsClosed] = useState(true);

    const handleClickCreateAccount = () => {
        postAccount({
            email: contract.souscripteur.email,
            owner_belair_id: 484568,
            nom: contract.souscripteur.lastname,
            date_naissance: contract.souscripteur.naissance,
            belair_id: contract.souscripteur.id,
        });
    };

    useEffect(() => {
        if (Number(props.match.params.id) !== contract.id) {
            fetchContract({ query });
        }
    });

    if (!isClosed) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }

    // const dateAffaireNouvelle = moment(contract.situation.dateAffaireNouvelle);
    const sixMonthsAgo = moment().subtract(6, 'months');

    const handleConfirmHamonChange = (value) => {
        postConfirmResilHamon({
            query: _.replace(query_resil_hamon, '{id}', id),
            form: { confirm_resil: value },
        });
    };

    return (
        <div className={'p-lg-4'}>
            <Card>
                <div>
                    <h3 className={'m-0 pb-3 border-bottom border-dark'}>
                        <b>{contract?.souscripteur?.nom}</b> - N°&nbsp;
                        <b>
                            {numeroPolice} - {isFleet ? libelleContrat : `${marque} ${modele}`} {!isFleet && !isNVEI && !isVAE && `${cylindree} cm3`}
                        </b>
                        <span className={`float-lg-right h6 sticker sticker-${statutCode}`}>
                            <span className={'d-none d-lg-inline-block'}>
                                Situation : <b>{statut}</b>
                            </span>
                        </span>
                    </h3>
                    {diff2 < 15 && (
                        <div className={'col-12 mt-4'}>
                            <div className={'alert alert-warning row'}>
                                <strong>Une carte verte a été envoyée pour ce client par voie postale. Il devrait la recevoir sous quelques jours.</strong>
                            </div>
                        </div>
                    )}
                    <SignaturesContainer />
                    <div className={'row'}>
                        <div className={`${avenant_link_fid ? 'col-md-8 mt-2 mb-2' : 'col-md-12 mt-2 mb-2'}`}>
                            {!isFleet && (
                                <>
                                    {isNVEI ? (
                                        <Link
                                            to={accident_mob_claim_link ? `/mon-contrat/${id}/ma-garantie-mobilite` : window.location.pathname}
                                            className={`${!accident_mob_claim_link && 'disabled amo-tooltip'} btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                        >
                                            <i className={'fas fa-street-view'} /> Garantie mobilité
                                            <small className={'tooltiptext'}>{getReason(accident_mob_claim_link_reason)}</small>
                                        </Link>
                                    ) : (
                                        <>
                                            {/*{isCourtierPremium && (*/}
                                            {/*    <span*/}
                                            {/*        className={`d-block d-lg-inline-block btn btn-primary max-rounded mr-0 mr-lg-3 mt-3 mb-3 mt-lg-0 ${*/}
                                            {/*            tiers_has_account && 'disabled amo-tooltip'*/}
                                            {/*        }`}*/}
                                            {/*        onClick={() => (!tiers_has_account ? handleClickCreateAccount() : null)}*/}
                                            {/*    >*/}
                                            {/*        Créer l'espace assuré*/}
                                            {/*        <small className={'tooltiptext'}>Ce client dispose déjà d'un espace assuré.</small>*/}
                                            {/*    </span>*/}
                                            {/*)}*/}
                                            {!isVAE && (
                                                <>
                                                    <APILink
                                                        href={_.startsWith(contract.vehicule?.codeProduit, '229127_') ? null : ri_link}
                                                        download
                                                        className={`${(!ri_link || _.startsWith(contract.vehicule?.codeProduit, '229127_')) && 'disabled amo-tooltip'} btn btn-ternary max-rounded d-block d-lg-inline-block mt-3 mb-3 mt-lg-0`}
                                                    >
                                                        <i className={'fas fa-file-pdf'} /> Éditer un RI
                                                        <small className={'tooltiptext'}>{(ri_link_reason && ri_link_reason.length > 0) ? getReason(ri_link_reason) : 'Action impossible sur ce contrat'}</small>
                                                    </APILink>
                                                    {!cantReportAccident && !isACL && !isWakam && (
                                                        <Link
                                                            to={accident_claim_link ? `/mon-contrat/${id}/declarer-un-sinistre` : window.location.pathname}
                                                            className={`${
                                                                !accident_claim_link && 'disabled amo-tooltip'
                                                            } btn btn-secondary max-rounded d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 mx-0 mx-lg-3`}
                                                        >
                                                            Déclarer un sinistre
                                                            <small className={'tooltiptext'}>{getReason(accident_claim_link_reason)}</small>
                                                        </Link>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {statutCode === '2' && hasWaitingReglement && quittance?.montantComptant && (
                                        <span
                                            className={`btn btn-primary max-rounded ${
                                                paymentLoading && 'disabled'
                                            } position-relative d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 mx-0 mx-lg-3`}
                                            onClick={() => {
                                                if (
                                                    !paymentLoading &&
                                                    window.confirm(
                                                        `Un lien de paiement d'un montant de ${formatEuro(
                                                            quittance.montantComptant,
                                                        )} sera envoyé à votre client. Ce lien est valable 48h. Êtes-vous sûr ?`,
                                                    )
                                                ) {
                                                    postDeposit();
                                                }
                                            }}
                                        >
                                            {paymentLoading && <div className='spinner-border position-absolute' style={{ left: '41%', top: '7%' }} />}
                                            Envoyer le lien de paiement
                                        </span>
                                    )}
                                </>
                            )}
                            {(isFleet && _.includes(['7311_CB2B', '7311_CB2C', '7311_CSHA'], contract.vehicule?.codeProduit) || isWakam ) && (
                                <>
                                {
                                    !isWakam ? <Link to={`/mon-contrat/${contract.id}/ma-flotte/ajout-vehicule`}
                                                     className={`btn btn-primary max-rounded mb-3`}>
                                        <i className="fas fa-plus mr-1"/>
                                        Ajouter un véhicule
                                    </Link> : <a target="_blank" href={contract.avenant_link}
                                                 className={`btn btn-primary max-rounded mb-3 ${avenant_link_reason[0] && 'disabled amo-tooltip'}`}>
                                        Ajout de véhicule/Changement de garanties
                                        <small className={'tooltiptext'}>{getReason(avenant_link_reason)}</small>
                                    </a>
                                }
                                    <APILink href={extract_vehicules_link} download rel="noopener noreferrer" className={'btn btn-ternary text-center mb-3 max-rounded ml-2'}>
                                        <i className={'fas fa-file-pdf'} /> Extraire la liste des véhicules
                                    </APILink>
                                </>
                            )}

                            {
                                isWakam && <Link to={avenant_link_reason[0] ? null : `/mon-contrat/${contract.id}/ma-flotte/sortie-vehicules`}
                                                 className={`btn btn-secondary max-rounded mb-3 mx-2 ${avenant_link_reason[0] && 'disabled amo-tooltip'}`}>
                                    <i className="fas fa-minus mr-1"/>
                                    Sortir des véhicules
                                    <small className={'tooltiptext'}>{getReason(avenant_link_reason)}</small>
                                </Link>
                            }

                            {permissions.includes('create amendment') && (
                                <>
                                    <Link
                                        to={!avenant_non_tarifant_link_reason[0] ? `/mon-contrat/${id}/modifier-les-informations-personnelles` : window.location.pathname}
                                        className={`${
                                            avenant_non_tarifant_link_reason[0] && 'disabled amo-tooltip'
                                        } btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                    >
                                        <i className={'fas fa-edit'}/> Modifier les infos personnelles
                                        <small
                                            className={'tooltiptext'}>{getReason(avenant_non_tarifant_link_reason)}</small>
                                    </Link>
                                    {!isNVEI ? (
                                        <>
                                            {(prelevement && contract.situation.statutCode === '1') && (
                                                <Link
                                                    to={!avenant_non_tarifant_link_reason[0] ? `/mon-contrat/${id}/modifier-le-rib` : window.location.pathname}
                                                    className={`${
                                                        avenant_non_tarifant_link_reason[0] && 'disabled amo-tooltip'
                                                    } btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                                >
                                                    <i className={'fas fa-edit'} /> Modifier le RIB
                                                    <small className={'tooltiptext'}>{getReason(avenant_non_tarifant_link_reason)}</small>
                                                </Link>
                                            )}

                                            {!isAIMCO && !isWakam && (
                                                <Link
                                                    to={(!avenant_non_tarifant_link_reason[0] && statutCode === '1') ? `/mon-contrat/${id}/modifier-adresse` : window.location.pathname}
                                                    className={`${
                                                        (avenant_non_tarifant_link_reason[0] || statutCode !== '1') && 'disabled amo-tooltip'
                                                    } btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                                >
                                                    <i className={'fas fa-edit'} /> Changement d'adresse
                                                    {/*<small className={'tooltiptext'}>{getReason(avenant_link_reason) ?? 'Le contrat doit être en cours pour être modifier'}</small>*/}
                                                    <small className={'tooltiptext'}>{getReason(avenant_non_tarifant_link_reason).length > 0 ? getReason(avenant_non_tarifant_link_reason) : 'Le contrat doit être en cours pour être modifier'}</small>
                                                </Link>
                                            )}

                                            { !isWakam && <a
                                                href={statutCode !== '5' && avenant_link ? avenant_link : null}
                                                target={'_blank'}
                                                rel='noopener noreferrer'
                                                className={`${
                                                    !avenant_link && 'disabled amo-tooltip'
                                                } btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                            >
                                                <i className={'fas fa-edit'} /> Faire un autre avenant
                                                <small className={'tooltiptext'}>
                                                    {statutCode === '5'
                                                        ? 'Si vous souhaitez effectuer une remise en vigueur, veuillez contacter nos services'
                                                        : getReason(avenant_link_reason)}
                                                </small>
                                            </a> }
                                            {isEligibleHivernage && !isWakam && (
                                                <Link
                                                    to={`/mon-contrat/${id}/hivernage`}
                                                    className={'btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded'}
                                                >
                                                    Gérer Ma période hivernale
                                                </Link>
                                            )}
                                            {isVAE && (contract.situation.statutCode === '1' || contract.situation.statutCode === '5' || contract.situation.statutCode === '2') && (
                                                <Link
                                                    to={`/mon-contrat/${id}/resilier`}
                                                    className={'btn mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded btn-danger'}
                                                >
                                                    Résilier / suspendre le contrat
                                                </Link>
                                            )}
                                            {!_.includes(['6', '5', '9', 'I'], contract.situation.statutCode) &&
                                                <Link
                                                    to={`/mon-contrat/${id}/resiliation`}
                                                    className={'btn mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded btn-danger'}
                                                >
                                                    Résilier / suspendre le contrat
                                                </Link>
                                            }
                                        </>
                                    ) : (
                                        contract.quittances && (
                                            <Link
                                                to={statutCode !== '2' ? `/mon-contrat/${id}/edition-attestation-assurance` : window.location.pathname}
                                                className={`${statutCode === '2' && 'disabled'} btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                            >
                                                <i className={'fas fa-edit'} /> Editer l'attestation d'assurance
                                            </Link>
                                        )
                                    )}
                                </>
                            )}
                            {
                                isNVEI && avenant_link && <a
                                    href={statutCode !== '5' && avenant_link ? avenant_link : null}
                                    target={'_blank'}
                                    rel='noopener noreferrer'
                                    className={`${
                                        !avenant_link && 'disabled amo-tooltip'
                                    } btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                >
                                    <i className={'fas fa-edit'}/> Faire un avenant
                                    <small className={'tooltiptext'}>
                                        {statutCode === '5'
                                            ? 'Si vous souhaitez effectuer une remise en vigueur, veuillez contacter nos services'
                                            : getReason(avenant_link_reason)}
                                    </small>
                                </a>
                            }
                            {canChangeFractionnement &&
                                <Link
                                    to={`/mon-contrat/${id}/changement-fractionnement`}
                                    className={`btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                >
                                    <i className={'fas fa-edit'}/> Passer en prélèvement
                                </Link>
                            }

                            <DocumentsClient quittance={quittance} contract={contract} ri_link={ri_link} ri_link_reason={ri_link_reason} />
                        </div>

                        {avenant_link_fid && moment(dateEcheance, 'YYYY-MM-DD').diff(moment(), 'days') <= 30 && (
                            <div className={'col-md-4  mt-2 mb-2 text-right'}>
                                <button
                                    type={'button'}
                                    className={`btn-fid btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded p-4`}
                                    data-toggle='modal'
                                    data-target='#modalFid'
                                    onClick={() => setIsClosed(!isClosed)}
                                >
                                    Votre client est éligible à une
                                    <br />
                                    <strong>OPERATION DE FIDELISATION</strong>
                                </button>
                            </div>
                        )}
                    </div>

                    <div className={`modal fade ${!isClosed && 'show d-block'}`} tabIndex='-1' role='dialog'>
                        <div className='modal-dialog modal-fid my-0' role='document'>
                            <div className='modal-content'>
                                <div className='modal-header border-0'>
                                    <h4 className={'mt-2 mb-2 font-weight-bold'}>OPERATION DE FIDELISATION</h4>
                                    <i className='fas fa-times cursor-pointer' onClick={() => setIsClosed(true)} />
                                </div>
                                <div className='modal-body d-flex flex-column flex-nowrap align-items-stretch' data-test={'children'}>
                                    <div className={'mb-3'}>
                                        L'ensemble de ce dispositif a pour vocation de vous aider à pérenniser les contrats de vos clients en leur proposant une alternative
                                        avantageuse :
                                    </div>
                                    <div className={'mb-3 text-center'}>
                                        <p className={'text-left'}>
                                            <span className={'font-weight-bold listing'}>1</span>
                                            Vous avez la possibilité d'effectuer un avenant en toute <b>autonomie</b>
                                        </p>
                                        <a
                                            href={avenant_link_fid}
                                            target={'_blank'}
                                            rel='noopener noreferrer'
                                            className={` btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}
                                        >
                                            <i className={'fas fa-edit'} /> Faire un avenant de fidélisation
                                        </a>
                                    </div>
                                    <div className={'mb-3 text-center'}>
                                        <p className={'text-left'}>
                                            <span className={'font-weight-bold listing'}>2</span>
                                            Si vous souhaitez être accompagné dans cette démarche, nous restons à votre disposition :
                                        </p>
                                        <p className={` btn btn-secondary mr-lg-3 d-block d-lg-inline-block mt-3 mb-3 mt-lg-0 max-rounded`}>
                                            <i className={'fas fa-phone'} /> Par téléphone au 02 47 51 36 15
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'mt-5 row m-0'}>
                        <div className='col-12 col-lg-6 pl-lg-0 pr-lg-3 p-0'>
                            <Cadre icon={'fas fa-user'} title={'Souscripteur / Assuré(e)'}>
                                <div className={'mt-5 mt-lg-0'}>
                                    {(paysNaissance === null || villeNaissance === null || nomNaissance === null) && !isWakam && (
                                        <div className={'col-12 mt-4'}>
                                            <div className={'alert alert-warning align-items-center row'}>
                                                <p style={{ fontSize: 13, margin: 0 }}>Les informations de naissance du client sont incomplètes, merci de les compléter</p>
                                                <Link
                                                    to={`/mon-contrat/${id}/modifier-les-informations-de-naissance`}
                                                    className={`ml-2 btn btn-secondary  d-block d-lg-inline-block mt-3 mt-lg-0 max-rounded`}
                                                >
                                                    <i className={'fas fa-edit'} />
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                    { !isWakam && <p>
                                        Assuré :{' '}
                                        <b className={'d-block float-lg-right'}>
                                            {contract.conducteur.prenom} {contract.conducteur.nom}
                                        </b>
                                    </p> }
                                    <p>
                                        Nom du souscripteur : <b className={'d-block float-lg-right'}>{contract.souscripteur.nom}</b>
                                    </p>
                                    <p>
                                        Adresse :{' '}
                                        <b className={'d-block float-lg-right'}>
                                            {contract.souscripteur.adresse.rue1} {contract.souscripteur.adresse.rue2}
                                        </b>
                                    </p>
                                    <p>
                                        Code Postal - Ville :{' '}
                                        <b className={'d-block float-lg-right'}>
                                            {contract.souscripteur.adresse.codePostal} {contract.souscripteur.adresse.ville}
                                        </b>
                                    </p>
                                    <p>
                                        Téléphone : <b className={'d-block float-lg-right'}>{formatPhone(contract.souscripteur.phone)}</b>
                                    </p>
                                    <p>
                                        Email : <b className={'d-block float-lg-right'}>{contract.souscripteur.email}</b>
                                    </p>

                                    { !isWakam && <>
                                        <p>
                                            Nom de naissance : <b
                                            className={'d-block float-lg-right'}>{nomNaissance || 'Non renseigné'}</b>
                                        </p>
                                        <p>
                                            Ville et pays de naissance :{' '}
                                            <b className={'d-block float-lg-right'}>
                                                {villeNaissance || 'Non renseigné'} - {paysNaissance || 'Non renseigné'}
                                            </b>
                                        </p>
                                        <p>
                                            Date de naissance : <b
                                            className={'d-block float-lg-right'}>{naissance ? moment(naissance).format('L') : '-'}</b>
                                        </p>
                                    </>}

                                    {permis.map((p) => (
                                        <p key={p.type}>
                                            Date de permis {p.type} : <b
                                            className={'d-block float-lg-right'}>{moment(p.date).format('L')}</b>
                                        </p>
                                    ))}
                                    {!isNVEI && !isFleet && !isVAE && (
                                        <p>
                                            CRM :{' '}
                                            <b className={'d-block float-lg-right'}>
                                                {crm} <span
                                                className={'text-lowercase'}>à la date du</span> {moment(dateCrm).format('L')}
                                            </b>
                                        </p>
                                    )}

                                    <p>
                                        Tiers payeur :{' '}
                                        <b className={'d-block float-lg-right'}>
                                            { !isWakam && <span>
                                                {tiersPayeur?.nom ? (
                                                    <>
                                                        {tiersPayeur.nom} {tiersPayeur.prenom || ''} {tiersPayeur.relation ? `(${_.find(tiersPayeurRelation, ['code', tiersPayeur.relation]).libelle})` : ''}
                                                    </>
                                                ) : (
                                                    'Non renseigné'
                                                )}
                                            </span> }
                                            <span>
                                                {!tiersPayeur.nom ? (
                                                    <Link to={`/mon-contrat/${contract.id}/ajout-tiers-payeur`}>
                                                        {' '} (Ajouter)
                                                    </Link>
                                                ) : (
                                                    <>
                                                        <Link to={`/mon-contrat/${contract.id}/edition-tiers-payeur/${tiersPayeur.id}`}>
                                                            <i className='fas fa-edit'></i>
                                                        </Link>
                                                        <button
                                                            className=''
                                                            onClick={() => {
                                                                if (window.confirm(`Êtes-vous sûr(e) de vouloir supprimer le tiers payeur ?`))
                                                                    submitDeleteTiersPayeur(document.code);
                                                            }}
                                                            style={{
                                                                border: 'none',
                                                                background: 'none',
                                                                color: 'red',
                                                            }}
                                                        >
                                                            <i className='fas fa-trash'></i>
                                                        </button>
                                                    </>
                                                )}
                                            </span>
                                        </b>
                                    </p>
                                </div>
                            </Cadre>
                        </div>
                        <div className='col-12 col-lg-6 pr-lg-0 pl-lg-3 p-0 mt-5 mt-lg-0'>
                            <Cadre icon={'fas fa-file-alt'} title={'Situation du contrat'}>
                                <div className={'mt-5 mt-lg-0'}>
                                    <p>
                                        Echéance principale : <b className={'d-block float-lg-right'}>{echeance}</b>
                                    </p>
                                    <p>
                                        Date affaire nouvelle : <b className={'d-block float-lg-right'}>{moment(dateAffaireNouvelle).format('L')}</b>
                                    </p>
                                    <p>Fractionnement : <b className={'d-block float-lg-right'}>{fractionnement ? fractionnement : '-'}</b>
                                    </p>
                                    {!isNVEI && (
                                        <p>
                                            Référence Unique de Mandat : <b className={'d-block float-lg-right'}>{rum ? rum : '-'}</b>
                                        </p>
                                    )}
                                    <p>
                                        Situation : <b className={'d-block float-lg-right'}>{statut}</b>
                                    </p>
                                    {motif && (
                                        <p>
                                            Motif : <b className={'d-block float-lg-right'}>{motif.length > 0 ? motif : '-'}</b>
                                        </p>
                                    )}
                                    <p>
                                        Origine du contrat : <b className={'d-block float-lg-right'}>{apporteurNom ? apporteurNom : '-'}</b>
                                    </p>
                                    {avantage && (
                                        <p>
                                            Avantage : <b className={'d-block float-lg-right'}>{avantage}</b>
                                        </p>
                                    )}
                                    {dernier_hivernage?.statutCode === '1' &&
                                        <p>
                                            Ma période hivernale : <b className={'d-block float-lg-right'}>Activée jusqu’au {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</b>
                                        </p>
                                    }

                                    {(hamon?.id && hamon?.confirm_resil !== 'T' && moment(dateAffaireNouvelle).isAfter(sixMonthsAgo)) ? (
                                        <div className="bloc-white me-4 position-relative text-center mt-5">
                                            <div className={'position-absolute info-block p-3'}>
                                                <i className="bi bi-info-circle text-light fs-4"/>
                                            </div>
                                            <div>
                                                <p className={'fw-bold text-danger fs-6 ms-4'}>
                                                    La souscription a été effectuée par le biais de la loi Hamon&nbsp;!
                                                </p>
                                                <p className={'fs-9'}>
                                                    Avez-vous reçu la confirmation par votre client de la résiliation de son ancienne assurance ?
                                                </p>
                                                <a onClick={() => handleConfirmHamonChange('T')} className={'fs-6 cursor-pointer'}
                                                   style={{cursor: 'pointer'}}>
                                                    Oui
                                                </a>

                                                <a onClick={() => handleConfirmHamonChange('F')}
                                                   className={'fs-6 mx-4 cursor-pointer'}>
                                                    Non
                                                </a>

                                                <a onClick={() => handleConfirmHamonChange('help')}
                                                    className={'fs-6 cursor-pointer'}>
                                                     Besoin d'aide
                                                </a>
                                            </div>
                                        </div>
                                    ) : hamon?.confirm_resil === 'T' ? (
                                        <div className="bloc-white col-lg-5 col-md-6 col-xs-12 me-4 position-relative text-center">
                                            <div className={'position-absolute info-block p-3'}>
                                                <i className="bi bi-info-circle text-light fs-4"/>
                                            </div>
                                            <div>
                                                <p className={'fw-bold text-success fs-6 ms-4'}>
                                                    Confirmation de résiliation reçue&nbsp;!
                                                </p>
                                                <p className={'fs-9'}>
                                                    Merci d'avoir confirmé la réception de la résiliation de votre ancienne assurance.
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </Cadre>
                        </div>
                    </div>

                    {isFleet && (
                        <div className={'mt-5 row m-0'}>
                            <div className='col p-0'>
                                <Cadre icon={'fas fa-motorcycle'} title={'Liste des véhicules'}>
                                    <FleetContainer id={contract.id} history={history} />
                                </Cadre>
                            </div>
                        </div>
                    )}

                    {(!isFleet || (isFleet && isWakam)) &&
                        <div className={'mt-5 row m-0'}>
                            <div className='col p-0'>
                                <Cadre icon={'fas fa-file-alt'} title={'Liste des documents'}>
                                    <DocumentsContractContainer {...props}/>
                                </Cadre>
                            </div>
                        </div>
                    }

                    {!isFleet && (
                        <>
                            <div className={'row m-0'}>
                                <div className='mt-5 col-lg-6 col-md-12 pl-lg-0 p-0 pr-lg-3'>
                                    <Cadre icon={'fas fa-motorcycle'} title={'Véhicule assuré'}>
                                        <p>
                                        Véhicule :{' '}
                                            <b className={'d-block float-lg-right'}>
                                                {marque} {modele}
                                            </b>
                                        </p>
                                        {!isVAE && (
                                            <>
                                                {!isNVEI && (
                                                    <>
                                                        <p>
                                                            Cylindrée :{' '}
                                                            <b className={'d-block float-lg-right'}>
                                                                {cylindree}cm<sup>3</sup>
                                                            </b>
                                                        </p>
                                                        <p>
                                                            Date de mise en circulation : <b className={'d-block float-lg-right'}>{dateMEC ? moment(dateMEC).format('L') : '-'}</b>
                                                        </p>
                                                        <p>
                                                            Immatriculation : <b className={'d-block float-lg-right'}>{immatriculation}</b>
                                                        </p>
                                                        <p>
                                                            Genre : <b className={'d-block float-lg-right'}>{genre ? genre : '-'}</b>
                                                        </p>
                                                        <p>
                                                            Valeur déclarée : <b className={'d-block float-lg-right'}>{valeur}&nbsp;€</b>
                                                        </p>
                                                        <p>
                                                            Lieu de stationnement : <b className={'d-block float-lg-right'}>{lieuStationnement}</b>
                                                        </p>
                                                    </>
                                                )}
                                                <p>
                                                    Usage : <b className={'d-block float-lg-right'}>{usage}</b>
                                                </p>
                                            </>
                                        )}
                                        {isVAE && <VaeVehicule vehicule={contract.vehicule} />}
                                    </Cadre>
                                </div>
                                <div className='mt-5 col-lg-6 col-md-12 pr-lg-0 p-0 pl-lg-3'>
                                    <Cadre icon={'fas fa-shield-alt'} title={'Garanties'}>
                                        <GarantiesContractContainer />
                                    </Cadre>
                                </div>
                            </div>
                        </>
                    )}

                    <div className={'mt-5 row m-0'}>
                        <div className='col p-0'>
                            <Cadre icon={'fas fa-file-alt'} title={'Liste des sinistres'}>
                                <AccidentsContractContainer />
                            </Cadre>
                        </div>
                    </div>

                    {!isFleet && (
                        <div className={'mt-5 row m-0'}>
                            <div className='col p-0'>
                                <Cadre icon={'fas fa-euro-sign'} title={'Quittances'}>
                                    <QuittancesContractContainer />
                                </Cadre>
                            </div>
                        </div>
                    )}

                    {contract.souscripteur.titre === 'SOC' && (
                        <div className={'mt-5 row m-0'}>
                            <div className='col p-0'>
                                <Cadre icon={'fas fa-users'} title={'Bénéficiaires effectifs'}>
                                    <BeneficiairesEffectifContainer />
                                </Cadre>
                            </div>
                        </div>
                    )}

                    {isCourtierPremium && tiers_has_account && (
                        <div className={'mt-5 row m-0'}>
                            <div className='col p-0'>
                                <Cadre icon={'fas fa-history'} title={'Dernières activités du client sur son espace'} large>
                                    <ActivitiesContractContainer />
                                </Cadre>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        </div>
    );
};

ContractDetail.defaultProps = {
    contract: {},
    isNVEI: false,
    permissions: [],
};

ContractDetail.propTypes = {
    contract: PropTypes.object,
    isNVEI: PropTypes.bool,
    permissions: PropTypes.array,
};

export default ContractDetail;
